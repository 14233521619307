import React from 'react'
import '../../styles/CardItem.css'

const CardItem = (values) => {
    const actionNav = ()=>{
        window.open(values.navUrl, '_blank');
    }
    return (
        <div className='grid-item card'>
            <div className='card-item-image'>
                {/* <img src={values.imgUrl} alt="" width={250} /> */}
                <img className="card-img-top" src={values.imgUrl} alt="Card image cap"></img>
            </div>
            <div className="card-body d-flex flex-column">
                <h5>{values.title}</h5>
                <span className="card-text">{values.description}</span>
                <button className='grid-item-btn' onClick={actionNav}>Click Here</button>
            </div>
            {/* <span>{values.description}</span>
            <button className='grid-item-btn' onClick={actionNav}>Click Here</button> */}
        </div>
    )
}

export default CardItem