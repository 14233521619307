// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* popup.css */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 430px;
  transform: translate(-50%, -50%);
  padding: 10px 16px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  }
  
  .popup.success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
  
  .popup.error {
    background-color: #5c2680;
    color: white;
    font-weight: 400;
    border-radius: 20px;
  }
  
  .popup button {
    margin-top: 10px;
    padding: 10px 15px;
    cursor: pointer;
  }

   .popup.error svg{
    cursor: pointer;
   }
   .popup.error svg:hover{
      border: 1px solid;
      padding: 3px 5px;
      border-radius: 12px;
      margin-left: -6px !important;
   }
   .popup.error p{
    margin: 0px;
    padding: 8px 0px 10px 0px;
   }`, "",{"version":3,"sources":["webpack://./src/styles/popup.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,gCAAgC;EAChC,kBAAkB;EAClB,sBAAsB;EACtB,sBAAsB;EACtB,uCAAuC;EACvC,kBAAkB;EAClB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB;;GAEC;IACC,eAAe;GAChB;GACA;MACG,iBAAiB;MACjB,gBAAgB;MAChB,mBAAmB;MACnB,4BAA4B;GAC/B;GACA;IACC,WAAW;IACX,yBAAyB;GAC1B","sourcesContent":["/* popup.css */\n.popup {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  min-width: 430px;\n  transform: translate(-50%, -50%);\n  padding: 10px 16px;\n  background-color: #fff;\n  border: 1px solid #ccc;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  }\n  \n  .popup.success {\n    background-color: #dff0d8;\n    border-color: #d6e9c6;\n    color: #3c763d;\n  }\n  \n  .popup.error {\n    background-color: #5c2680;\n    color: white;\n    font-weight: 400;\n    border-radius: 20px;\n  }\n  \n  .popup button {\n    margin-top: 10px;\n    padding: 10px 15px;\n    cursor: pointer;\n  }\n\n   .popup.error svg{\n    cursor: pointer;\n   }\n   .popup.error svg:hover{\n      border: 1px solid;\n      padding: 3px 5px;\n      border-radius: 12px;\n      margin-left: -6px !important;\n   }\n   .popup.error p{\n    margin: 0px;\n    padding: 8px 0px 10px 0px;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
