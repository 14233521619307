import React, { useEffect, useState } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../../Config/config';
import axios from 'axios';
import Sidebar from '../../components/SideBars/SideBar';
import '../../styles/users.css'
import Navbar from '../../components/NavBar/Navbar';
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import api from '../../api/api';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
// import SideBarPage from './SideBarPage';

const UserViewPage = () => {
  const {id, empId} = useParams();
  const [userDetails, setUser] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const userEdit = (empid) =>{
    navigate(`/edit-user/${id}/${empid}`)
  }

  const userDelete = async (empid) => {
    const userConfirmed = window.confirm('Are you sure you want to delete the Employee?');

    if (userConfirmed) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate("/login");
          return;
        }

        await api.delete(`${baseUrl}/api/delete-user/${empid}/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        navigate(`/all-users/${id}`);
      } catch (error) {
        console.error('Error deleting entry:', error);
      }
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const token = localStorage.getItem('token'); //retrive the token from the local storage
        if(!token){
          navigate("/login")
        }else{
          const response = await api.get(`${baseUrl}/api/user-details/${empId}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setUser(response.data);

          const userresponse = await api.get(`${baseUrl}/api/user-details/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          console.log('user',userresponse.data);
          setCurrentUserDetails(userresponse.data);
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, [id, navigate, empId, isHovered]);

  return (
    <div className='content-section flex-wrap'>
      <Navbar />
      <div className='body-section'>
        <div className="profile-container"> 
        <div className='backBtn' onClick={() => navigate(-1)}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>
          <div className='container align-middle'>
                {userDetails ? (
                  <>
                    <div className='row justify-content-center mb-4'>
                      <div className='col-12 col-md-8 col-lg-8 col-xl-5 mb-4 mb-md-0'>
                        <div className="profile-details">
                          <div className="profile-hd section-highlight mb-4">
                            <div className="profile-img">
                              {userDetails.profile_pic === ''|| !userDetails.profile_pic ? 
                                <img src="/images/profile-user.png" alt="profile-image" className='rounded-image'/> 
                              : <img src={userDetails.profile_pic} alt="profile-image" className='rounded-image'/>
                              }
                            </div>
                            <div className="profile-short">
                              <span className='emp-name'>{userDetails.first_name} {userDetails.last_name}</span>
                              <span>{userDetails.email}</span>
                            </div>
                            <div className='status-section'>
                              <span>{userDetails.is_active === 'Y' ? 'Active' : 'InActive'}</span>
                            </div>
                          </div>
                          <div className='allocation-details section-highlight'>
                            <div className="profile-allocation-data">
                              <span className='sub-title-h4'>Seat Allocation</span>
                              <span className='content-text'>{userDetails.seat_allocation}</span>
                            </div>
                          </div>
                        </div>  
                      </div>  
                      <div className='col-12 col-md-4 col-lg-4 col-xl-2 mb-0 my-auto'>
                        <div className="profile-description section-highlight">
                            {/* <div className="profile-desc-hd">
                              <span>Details</span>
                            </div> */}
                            <div className="profile-employee-id mb-4">
                              <span className='sub-title-h4'>Joining Date</span>
                              <span className='content-text'>{new Date(userDetails?.joining_date).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>
                            </div>

                            <div className="profile-employee-id mb-4">
                              <span className='sub-title-h4'>Role</span>
                              <span className='content-text'>{userDetails.role}</span>
                            </div>

                            <div className="profile-employee-id mb-4">
                              <span className='sub-title-h4'>Employee ID</span>
                              <span className='content-text'>{userDetails.employeeId}</span>
                            </div>
                            {currentUserDetails && currentUserDetails.access_type === 1 && (
                              // isHovered ? (
                                <div className='profile-action-icons' onMouseEnter={() => setIsHovered(true)}  onMouseLeave={() => setIsHovered(false)}>
                                  <div className="profile-edit-section">
                                    <div className='profile-action '>
                                      <FiEdit3 className="action-icon button-style" onClick={() => userEdit(userDetails.employeeId)}/>
                                    </div>
                                    <div className='profile-action-title'>
                                      <span className='sub-title-h4'>Edit</span>
                                    </div>
                                  </div>
                                  <div className='profile-delete-section'>
                                    <div className='profile-action '>
                                      <BsTrash className="action-icon button-style" onClick={() => userDelete(userDetails.employeeId)}/>                                </div>
                                    <div className='profile-action-title'>
                                      <span className='sub-title-h4'>Delete</span>
                                    </div>
                                  </div>
                                </div>
                              // ) : (
                              //     <div className='profile-delete-section'>
                              //       <div className='profile-action '>
                              //         <BsThreeDotsVertical className="three" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}/>
                              //       </div>
                              //       <div className='profile-action-title'>
                              //         <span className='sub-title-h4'>Action</span>
                              //       </div>
                              //     </div>
                              //   )
                              )
                              }
                        </div>
                      </div>
                    </div>
                    <div className='row justify-content-center'>
                      <div className='col-12 col-md-12 col-lg-12 col-xl-7'>
                        <div className="profile-phone-section section-highlight mb-4 flex-column flex-md-row gap-4 gap-md-0 p-5 p-md-4">
                          <div className="profile-phone">
                            <div className='profile-phone-logo'>
                              <img src='/images/icons8-phone-48.png' alt="profile image"  className="profile-icon"/>
                            </div>
                            <div className="profile-phone-number">
                              <span className='sub-title-h4'>Contact</span>
                              <span className='content-text'>{userDetails.contact_no}</span>
                            </div>
                          </div>
                          
                          <div className="profile-phone-link button-style">
                            <Link to={`tel:${userDetails.contact_no}`}>
                              <span>call</span>
                            </Link>
                          </div>
                        </div>
                        <div className="profile-phone-section section-highlight flex-column flex-md-row gap-4 gap-md-0 p-5 p-md-4">
                          <div className="profile-email">
                            <div className='profile-mail-logo'>
                              <img src='/images/icons8-mail-48.png' alt="profile image"  className="profile-icon"/>
                            </div>
                            <div className="profile-phone-number">
                              <span className='sub-title-h4'>Email</span>
                              <span className='content-text'>{userDetails.email}</span>
                            </div>
                          </div>
                          
                          <div className="profile-phone-link button-style">
                            <Link to={`mailto:${userDetails.email}`}>
                              <span>Message</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </>
                ) : (
                  <p>Loading user details...</p>
                )}
                  {/* <h2 className="profile-heading">Personal Information</h2>
                  {userDetails ? (
                      <div className="profile-details">
                          <div className="profile-info">
                              <p className="profile-info">Name: {userDetails.first_name} {userDetails.last_name}</p>
                              <p className="profile-info">Employee ID: {userDetails.employeeId}</p>
                          </div>
                          <div className="profile-info">
                              <p className="profile-info">Role: {userDetails.access_type === '1' ? 'Admin' : 'Employee'}</p>
                              <p className="profile-info">Email: {userDetails.email}</p>
                          </div>
                          <div className="profile-info">
                              <p className="profile-info">Contact Number: {userDetails.contact_no}</p>
                          </div>
                      </div>
                  ) : (
                      <p>Loading user details...</p>
                  )} */}
          </div>
        </div>
        </div>
    </div>
  )
}

export default UserViewPage