import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/styles.css'; // Import the CSS file
import Popup from '../../components/PopUps/Popup';
import { useNavigate } from 'react-router-dom';
import baseUrl from '../../Config/config';
import api from '../../api/api';
import Spinner from 'react-bootstrap/Spinner';
// axios.defaults.withCredentials = true;

const Login = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    employeeId: '',
    password: '',
  });
  const [popup, setPopup] = useState(null);
  const [loading, setLoading] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [ssoResponse, setSsoResponse] = useState("")

  useEffect(() => {
    const lastAttemptTime = localStorage.getItem('lastAttemptTime');
    const failedAttempts = localStorage.getItem('failedAttempts');
    if (lastAttemptTime && failedAttempts >= 3) {
      const diff = (new Date().getTime() - new Date(lastAttemptTime).getTime()) / 1000;
      if (diff < 300) {
        setIsDisabled(true);
        setRemainingTime(300 - diff);
      } else {
        localStorage.removeItem('lastAttemptTime');
        localStorage.removeItem('failedAttempts');
      }
    }
  }, []);


  // Updated SSO call function
  const ssoCallFn = async () => {
    try {
      const csrfToken = await fetchCsrfToken();
      if (csrfToken) {
        const response = await api.post(`${baseUrl}/login`, {}, {
          headers: {
            'X-CSRF-Token': csrfToken
          }
        });
        console.log("this is response", response);
        setSsoResponse(response.data.ssoResponse);
        if (response.data.ssoResponse === "Success") {
          setPopup({ type: 'success', message: response.data });
          setFormData({ employeeId: '' });
          localStorage.setItem('token', response.data.accessToken); // SET THE TOKEN IN THE LOCAL STORAGE
          localStorage.setItem('authenticated', true);
          setAuthenticated(true);
          localStorage.setItem('user_id', Number(response.data.employeeId));
          localStorage.removeItem('failedAttempts');
          localStorage.removeItem('lastAttemptTime');
          console.log('emp', response.data.employeeId);
          setLoading(false)
          navigate(`/home/${response.data.employeeId}`);
        } else if (response.data.ssoResponse === "Failed") {
          navigate("/error")
        }else if (response.data.ssoResponse === "NoSSO") {
          setLoading(false)
        } else {
          setLoading(false)
          console.log(response.data);
          return;
        }
      } else {
        console.log('CSRF token not found');
      }
    } catch (error) {
      setLoading(false)
      console.error('Error in SSO call:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    ssoCallFn();
  }, []);

  useEffect(() => {
    if (isDisabled) {
      const interval = setInterval(() => {
        const lastAttemptTime = localStorage.getItem('lastAttemptTime');
        const diff = (new Date().getTime() - new Date(lastAttemptTime).getTime()) / 1000;
        if (diff >= 3) {
          setIsDisabled(false);
          setRemainingTime(0);
          localStorage.removeItem('lastAttemptTime');
          localStorage.removeItem('failedAttempts');
        } else {
          setRemainingTime(3 - diff);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isDisabled]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = (name === 'employeeId') ? Number(value) : value;

    setFormData({ ...formData, [name]: newValue });
  };

  const fetchCsrfToken = async () => {
    const response = await api.get('/api/csrf-token');
    const cookies = document.cookie.split('; ');
    const token = cookies.find((row) => row.startsWith('XSRF-TOKEN=')).split('=')[1];
    return token;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validation: Check if any input field is empty
    const isFormDataValid = Object.values(formData).every((value) => {
      return typeof value === 'string' ? value.trim() !== '' : value !== null && value !== undefined && value !== '';
    });

    if (!isFormDataValid) {
      setPopup({ type: 'error', message: 'All fields must be filled out' });
      return;
    }

    try {
      const token = await fetchCsrfToken();
      if (token) {
        const response = await api.post(`${baseUrl}/login`, { employeeId: formData.employeeId, password: formData.password });
    
        // Check response status
        if (response.status === 200) {
          // User successfully logged in
          if (response.data.message === 'User successfully logged in') {
            setPopup({ type: 'success', message: response.data.message });
            setFormData({ employeeId: '' });
            localStorage.setItem('token', response.data.accessToken); // SET THE TOKEN IN THE LOCAL STORAGE
            localStorage.setItem('authenticated', true);
            setAuthenticated(true);
            localStorage.setItem('user_id', Number(response.data.employeeId));
            localStorage.removeItem('failedAttempts');
            localStorage.removeItem('lastAttemptTime');
            console.log('emp', response.data.employeeId);
            navigate(`/home/${response.data.employeeId}`);
          } 
          // Password expired
          else if (response.data.message === 'Password expired, please update your password') {
            localStorage.setItem('token', response.data.accessToken); // SET THE TOKEN IN THE LOCAL STORAGE
            localStorage.setItem('authenticated', true);
            setAuthenticated(true);
            localStorage.setItem('user_id', formData.employeeId);
            localStorage.removeItem('failedAttempts');
            localStorage.removeItem('lastAttemptTime');
            setPopup({ type: 'error', message: 'Password expired, please update your password' });
            navigate(`/change-pwd/${formData.employeeId}`);
          }
        } 
        // Unauthorized (401) or other errors
        else if (response.status === 401) {
          setPopup({ type: 'error', message: response.data.message });
          handleFailedAttempt();
        } else {
          setPopup({ type: 'error', message: 'An unexpected error occurred.' });
          handleFailedAttempt();
        }
      } else {
        console.log('CSRF token not found');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setPopup({ type: 'error', message: error.response.data.message });
      } else {
        console.error('Error logging in:', error);
        setPopup({ type: 'error', message: 'Something went wrong. Please try again.' });
      }
      handleFailedAttempt();
    }
  };

  const handleFailedAttempt = () => {
    const failedAttempts = localStorage.getItem('failedAttempts') || 0;
    const newFailedAttempts = parseInt(failedAttempts) + 1;
    localStorage.setItem('failedAttempts', newFailedAttempts);
    if (newFailedAttempts >= 3) {
      localStorage.setItem('lastAttemptTime', new Date().toISOString());
      setIsDisabled(true);
    }
  };

  console.log(localStorage);

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <>
      {loading ?
        <div className="center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        :
        <div className='center'>
          <form className="register-form" onSubmit={handleLogin}>
            <img className="login-icon" src="/images/polus.webp" alt="" />
            <div className="form-row">
              <div className="form-group">
                <label className="emp-id-class" htmlFor="employeeId">Employee ID:</label>
                <input
                  type="text"
                  id="employeeId"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
              </div>
            </div>

            <button className="login-btn" type="submit" disabled={isDisabled}>
              Login
            </button>
            {isDisabled && <div className="retry-message">Please wait {Math.ceil(remainingTime)} seconds before retrying.</div>}
          </form>

          {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
        </div>
      }
    </>
  );
};

export default Login;
