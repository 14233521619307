import React, { useEffect, useState } from 'react'
import '../../styles/home.css'; // Import your CSS file for styling
// import Sidebar from '../../components/SideBars/SideBar';
import axios from 'axios';
import baseUrl, { assetUrl, personalAssetUrl, sofwareUrl, serviceTrackerUrl} from '../../Config/config';
import {frontEndUrl} from '../../Config/config';
import Popup from '../../components/PopUps/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/NavBar/Navbar';
import CardItem from '../../components/Cards/CardItem';
import api from '../../api/api';
// import SideBarPage from './SideBarPage';

export const HomePage = () => {
    const { id } = useParams();
    const [userDetails, setDetails] = useState(null);
    const [popup, setPopup] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token'); //retrive the token from the local storage
    var urlParam = token ? `?token=${token}` : ``;
    //const baseUrl = process.env.FRONTEND_URL;
    useEffect(() => {
        const fetchDetails = async () => {
          try {
            if(!token){
              navigate("/login")
            }else{
                const response = await api.get(`${baseUrl}/api/user-details/${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
              });
              setDetails(response.data);
              console.log(response.data);
              
              console.log('BASE',baseUrl);
              if(!(response.data.is_loggedIn)){
                navigate(`/change-pwd/${id}`)
                // setPopup({ type: 'error', message: 'Change your password to continue !', action : 'change_password'  });
              }
            }
          } catch (error) {
            console.error('Error fetching details:', error);
          }
        };
    
        fetchDetails();
      }, [id]);

      const closePopup = () => {
        setPopup(null);
        navigate(`/change-pwd/${id}`)
      };

    return (
       
            <div className='content-section flex-wrap'>
              <Navbar />
              <div className='body-section'>
                <div className="container">
                  <div className='body-section-inner'>
                  <div className="row justify-content-md-center">
                    <div className='col-12 p-0'>
                      <div className='home-txt-content'>
                        <div className='home-txt'>
                          <h1>Welcome to Polus Corporate Portal!</h1>
                          <p>A portal consolidating all internal and external applications used in the organization. It also includes an Employee Address Book and User Administration feature to manage access management of different applications.</p>
                        </div>
                        <div className="col_2"> 
                          <img src="/images/happy-employee.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center gap-4 gap-xl-2 category-section ">
                    <div className='col-12 text-center'>
                      <h2>External Applications</h2>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/polus.webp" description="Our company website available to the public explaining our products, services, portfoliocase studies, blogs etc.  " navUrl="http://polussolutions.com/"/>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/image.png" description="HR Management System and Payroll Software managing employee data, leave management, payslips, payrolls, income tax declarations etc." navUrl="https://polus.greythr.com/"/>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/redmine.png" description="Web-based time sheet management tool used for recording the efforts spent on the project tasks and responsibilities" navUrl="https://redmine.polussolutions.com/login"/>
                    </div>
                  </div>
                  <div className="row justify-content-md-center gap-4 gap-xl-2 category-section internal-resources-section">
                    <div className='col-12 text-center'>
                      <h2>Internal Applications</h2>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/service_tracker.png" title="Service Tracker" description="Application to deliver resilient IT services and resolve issues quickly providing employees amazing experience..." navUrl={`${serviceTrackerUrl}`} />
                    </div>
                    
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/assets.png" title="Asset Management" description="Application to manage company assets and their maintenance " navUrl={`${assetUrl}/login${urlParam}`} />
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/device-management.png" title="Personal Device Registration " description="Application to register, track and control the personal devices used in the Office Network" navUrl={`${personalAssetUrl}/login${urlParam}`} />
                    </div>
                    
                    <div className="col-12 col-md-5 col-lg-4 col-xl-4 p-2">
                      <CardItem imgUrl="/images/software-corner.png" title="Software Corner" description="Page publishing the whitelisted softwares and their trusted downloadable links " navUrl={`${sofwareUrl}/login${urlParam}`} />
                    </div>
                  </div>
                  </div>
                </div>
                {popup && <Popup type={popup.type} message={popup.message} action={popup.action} onClose={closePopup} />}
              </div>
           </div>
       
    )
}
