import axios from 'axios';

import baseUrl from '../Config/config';

const api = axios.create({
  baseURL: baseUrl, // Update with your API base URL
  withCredentials: true, // Ensure cookies are sent with each request
});

// Request interceptor to add CSRF token to headers
api.interceptors.request.use(config => {
  console.log("cookie", document.cookie);
  const token = document.cookie
    .split('; ')
    .find(row => row.startsWith('XSRF-TOKEN='))
    ?.split('=')[1];
  if (token) {
    
    config.headers['X-XSRF-TOKEN'] = token;
  }
  return config;
});

// Response interceptor to handle errors
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      // Token expired or not authorized
      localStorage.removeItem('token'); // Clear the token
      localStorage.removeItem('authenticated');
      localStorage.removeItem('user_id');
      alert("Session timeout. Please login again.");
      window.close(); // Close the page when the token expires
    }
    return Promise.reject(error);
  }
);

export default api;
