import { Route, Routes, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/LoginPage/Login";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import { useEffect, useState } from "react";
import { HomePage } from "./pages/HomePage/HomePage";
import { UsersPage } from "./pages/AllUsersPage/UsersPage";
import UserViewPage from "./pages/UserViewPage/UserViewPage";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import UpdateUserPage from "./pages/UpdateUser/UpdateUserPage";
import ChangePwdPage from "./pages/ChangePasswordPage/ChangePwdPage";
import Sidebar from "./components/SideBars/SideBar";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );
  const location = useLocation();
  console.log("windown pathname",window.location.pathname)

  useEffect(() => {
    // Update localStorage when authentication state changes
    localStorage.setItem("authenticated", authenticated);
  }, [authenticated]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={<Login setAuthenticated={setAuthenticated} />}
        />
        {authenticated ? (
          <Route path="/hero" element={<Navigate to={`/home/${localStorage.getItem("user_id")}`} replace />} />
        ) : (
          <Route path="/hero" element={<Navigate to="/login" replace />} />
        )}
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/logout-success" element={<LogoutPage/>} />
      </Routes>

      {authenticated && (
        <div className="main-content flex-column flex-xl-row">
          {!location.pathname.includes("/change-pwd") && (
            <div className="sidebar-section min-vh-0">
              <Sidebar setAuthenticated={setAuthenticated} />
            </div>
          )}
          <Routes>
            <Route path="/home/:id" element={<HomePage />} />
            <Route path="/profile/:id" element={<ProfilePage />} />
            <Route path="/all-users/:id" element={<UsersPage />} />
            <Route path="/add-user/:id" element={<AddUserPage />} />
            <Route path="/view-user/:id/:empId" element={<UserViewPage />} />
            <Route path="/edit-user/:id/:empId" element={<UpdateUserPage />} />
            <Route path="/change-pwd/:id" element={<ChangePwdPage />} />
            
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
