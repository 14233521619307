import React, { useEffect, useState } from 'react';
import '../../styles/Table/Table.css';
import baseUrl from '../../Config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { BsInfoCircle, BsTrash, BsThreeDotsVertical } from 'react-icons/bs';
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from 'react-icons/hi';
import { FaPlus } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import api from '../../api/api';import Navbar from '../../components/NavBar/Navbar';
import useDebounce from '../../functions/useDeboune';

export const UsersPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState(null);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [changed, setChanged] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage ? parseInt(savedPage, 10) : 1; // Default to page 1 if not found
  });
  const itemsPerPage = 8;
  const [userDetails, setDetails] = useState(null);
  const userId = localStorage.getItem('user_id');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    fetchDetails(currentPage, itemsPerPage, debouncedSearch);
  }, [debouncedSearch, currentPage, changed]);

  const fetchDetails = async (page = 1, limit = 8, search = '') => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate("/login");
        return;
      }
      
      const response = await api.get(`${baseUrl}/api/all-users`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: { page, limit, search }
      });

      const { users, totalUsers } = response.data;
      setAllUsers(users);
      setFilteredUsers(users);
      setRowCount(totalUsers);

      const userDetailsResponse = await api.get(`${baseUrl}/api/user-details/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setDetails(userDetailsResponse.data);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    localStorage.setItem('currentPage', newPage);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1)
  };

  const userView = (empid) => {
    navigate(`/view-user/${id}/${empid}`);
  };

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const addUser = () => {
    navigate(`/add-user/${id}`);
  };

  const userEdit = (empid) => {
    navigate(`/edit-user/${id}/${empid}`);
  };

  const userDelete = async (empid) => {
    const userConfirmed = window.confirm('Are you sure you want to delete the Employee?');

    if (userConfirmed) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate("/login");
          return;
        }

        await api.delete(`${baseUrl}/api/delete-user/${empid}/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setChanged(!changed);
      } catch (error) {
        console.error('Error deleting entry:', error);
      }
    }
  };

  return (
    <div className="content-section flex-wrap">
      <Navbar />
      <div className='body-section'>
        <div className="datatable-section">
          <div className="emppl-head-section">
            <div className="table-heading">Address Book</div>
            <div className="empl-search">
              <input type="text" placeholder='Search Employees' value={search} onChange={handleSearch} />
            </div>
            {userDetails && userDetails.access_type === 1 && (
              <div className="add-empl-btn" onClick={addUser}>
                <FaPlus />
              </div>
            )}
          </div>
          {allUsers ? (
            <div className='table-section'>
              <div className='table-container'>
                <table>
                  <thead className="custom-thead">
                    <tr>
                      <th className='name-hd'>Name</th>
                      <th>Designation</th>
                      <th>ID</th>
                      <th>Email</th>
                      <th>Contact</th>
                      {userDetails && userDetails.access_type === 1 && (
                        <th className='action-head'>Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {allUsers.map((item, index) => (
                   <tr key={index} onClick={() => userView(item.employeeId)}>
                   <td>
                     <div className="name-td">
                       <div className="name-td-image">
                         {item.profile_pic ? (
                           <img width={50} height={50} src={item.profile_pic} alt="profile-image" className='rounded-image' />
                         ) : (
                           <img width={50} height={50} src="/images/profile-user.png" alt="profile-image" className='rounded-image' />
                         )}
                       </div>
                       <div className="name-td-txt">{item.first_name}{" "}{item.last_name}</div>
                     </div>
                   </td>
                   <td>{item.role}</td>
                   <td>{item.employeeId}</td>
                   <td>{item.email}</td>
                   <td>{item.contact_no}</td>
                   {userDetails && userDetails.access_type === 1 && (
                     <td className='actions-column'>
                       {hoveredRow === index ? (
                         <div className='action-icons'>
                           <BsInfoCircle
                             className="action-icon"
                             onClick={(e) => {
                               e.stopPropagation(); // Stop the event from bubbling up to the <tr>
                               userView(item.employeeId);
                             }}
                           />
                           <FiEdit3
                             className="action-icon"
                             onClick={(e) => {
                               e.stopPropagation(); // Stop the event from bubbling up to the <tr>
                               userEdit(item.employeeId);
                             }}
                           />
                           <BsTrash
                             className="action-icon"
                             onClick={(e) => {
                               e.stopPropagation(); // Stop the event from bubbling up to the <tr>
                               userDelete(item.employeeId);
                             }}
                           />
                         </div>
                       ) : (
                         <BsThreeDotsVertical
                           className="three"
                           onMouseEnter={() => handleMouseEnter(index)}
                           onMouseLeave={handleMouseLeave}
                         />
                       )}
                     </td>
                   )}
                 </tr>
                 
                    ))}
                  </tbody>
                </table>
              </div>

              {rowCount > itemsPerPage && (
                <div className="pagination-container">
                  <div className="pagination">
                    <ul className="pagination-lists">
                      <li className="page-item-prev flex-center">
                        {currentPage > 1 && (
                          <a
                            onClick={() => handlePageChange(currentPage - 1)}
                            className="pagination-link"
                            href="#"
                          >
                            <HiOutlineArrowSmLeft className='nxt-prev-icon' />
                          </a>
                        )}
                        {currentPage === 1 && <HiOutlineArrowSmLeft className='nxt-prev-icon' />}
                      </li>
                      {Array.from({ length: Math.min(5, Math.ceil(rowCount / itemsPerPage)) }, (_, index) => {
                        const page = currentPage - 2 + index;
                        return page > 0 && page <= Math.ceil(rowCount / itemsPerPage) ? (
                          <li key={index} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <a
                              onClick={() => handlePageChange(page)}
                              className="page-link"
                              href="#"
                            >
                              {page}
                            </a>
                          </li>
                        ) : null;
                      })}
                      <li className="page-item-nxt flex-center">
                        {currentPage < Math.ceil(rowCount / itemsPerPage) && (
                          <a
                            onClick={() => handlePageChange(currentPage + 1)}
                            className="pagination-link"
                            href="#"
                          >
                            <HiOutlineArrowSmRight className='nxt-prev-icon' />
                          </a>
                        )}
                        {currentPage >= Math.ceil(rowCount / itemsPerPage) && <HiOutlineArrowSmRight className='nxt-prev-icon' />}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="center">
              <Spinner animation="grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
